@import "variables";

h1.entry-title {
    font-family: 'TradeGothic' !important;
}

.blog-section-aside-entry-header{
    & h2.blog-section-aside-entry-title{
        font-family: 'TradeGothic' !important;
    }
}

.blog-header{
    & h2.entry-title {
        font-family: 'TradeGothic' !important;
    }
}

.blog-section-2-header{
    & h2.entry-title {
        font-family: 'TradeGothic' !important;
    }
}


h3.entry-expert {
    font-weight: 400;
    color: $post_meta;
    font-size: 16px;
    line-height: 130%;
}

a.text-autor{
    color: $section_title;
    font-weight:normal;
}

a.text-acceso{
    color: $white;
    font-weight: 600;
    &:hover{
        color: $white !important;
    }
}

.header-social-bookmark{
    & a:hover,
    & i:hover{
        color: $white !important;
    }
}

.topbar-sblock:after{
    background-color: $white !important;
}

.blog {

    &-header {
        & .entry {
            &-title {
                color: $section_title !important;
                font-size: $section_title_size !important;
                font-weight: 600;
                margin-bottom: 30px;
            }
        }
    }

    &-content {
        & .article {
            &-content {
                margin-bottom: 30px;

                & .entry {
                    &-title {
                        color: $post !important;
                        font-size: $post_size !important;
                        font-weight: 400;
                        line-height: normal;
                    }
                }
            }
        }
    }

    &-section-1 {
        margin: 60px 0;

        &-content {
            & .article {
                &-content {
                    & .entry {
                        &-title {
                            color: $post !important;
                            font-size: $post_size !important;
                            font-weight: 400;
                            line-height: normal;
                        }
                    }
                }
            }
        }
    }

    &-section-2 {
        &-header {
            & .entry {
                &-header {
                    background-color: $bck_entryheader;
                    padding: 30px 0;
                    margin: 0 0 12px;
                }

                &-title {
                    color: $post;
                    font-weight: 400;
                    font-size: $section_title_size;
                }
            }
        }

        &-content {
            & .filter {
                margin-bottom: 40px;

                & .form-control {
                    margin-bottom: 0;
                    border-radius: 0;
                }

                & button {
                    background: #fff none repeat scroll 0 0;
                    border: 1px solid #e6e6e6;
                    box-shadow: none;
                    color: #7c8c99;
                    height: 45px;
                }
            }

            & .article-content {
                margin-bottom: 30px;
                &.min-margin{
                    margin-bottom: 20px;
                }

                & .entry-header {
                    & .entry-title {
                        color: $post_meta !important;
                        font-size: $post_size !important;
                        font-weight: 600;
                        line-height: normal;
                    }
                }

                & .entry-content {
                    & .entry-expert {
                        color: $post_expert;
                        font-size: $post_expert_size;
                        font-weight: 400;
                    }
                }
            }

        }

        &-content-2 {

            & .filter {
                margin-bottom: 40px;

                & .form-control {
                    margin-bottom: 0;
                    border-radius: 0;
                }

                & button {
                    background: #fff none repeat scroll 0 0;
                    border: 1px solid #e6e6e6;
                    box-shadow: none;
                    color: #7c8c99;
                    height: 45px;
                }
            }

            & .article-content {
                margin-bottom: 20px;

                & .entry-header {
                    & .entry-title {
                        color: $post_meta !important;
                        font-size: $post_size !important;
                        font-weight: 600;
                        line-height: normal;
                    }
                }

                & .entry-content {
                    & .entry-expert {
                        color: $post_expert;
                        font-size: $post_expert_size;
                        font-weight: 400;
                    }
                }
            }

        }
    }

    &-section-aside {

        &-entry-title {
            color: $post !important;
            font-size: $post_size;
            font-weight: 600;
            margin-bottom: 40px;
        }

        &-content {
            & .article-content {
                margin-bottom: 50px;

                &-entry-title {
                    color: $post_meta !important;
                    font-size: $post_size !important;
                    font-weight: 600;
                    line-height: normal;
                }

                &-entry-expert {
                    color: $post_expert;
                    font-size: $post_expert_size;
                    font-weight: 400;
                    display: none;
                }
            }

            & .filter {
                margin-bottom: 40px;

                & .form-control {
                    margin-bottom: 0;
                    border-radius: 0;
                }

                & button {
                    background: #fff none repeat scroll 0 0;
                    border: 1px solid #e6e6e6;
                    box-shadow: none;
                    color: #7c8c99;
                    height: 45px;
                }
            }
        }
    }

    &-single {
        &-header {
            & .entry {
                &-header {
                    background-color: $bck_entryheader;
                    padding: 30px 0;
                    margin: 0 0 12px;
                }

                &-title {
                    color: $post;
                    font-weight: 400;
                    font-size: $section_title_size;
                }
            }
        }

        &-content {

            & .article-content {

                & .entry {
                    &-content {
                        margin: 30px 0 60px;
                        color: $post_expert;
                        font-size: 21px;
                        font-weight: 400;
                        line-height: 190.5%;

                        & .form-control{
                            border-radius: 0;
                        }

                        & .single-input {
                            margin-bottom: 20px;

                            & .text-danger{
                                font-size: 16px;
                            }

                            & input {
                                margin-bottom: 0;
                            }
                        }
                    }
                }
            }

        }

        &-container {
            display: flex;
            justify-content: center;
            align-items: center;

            & .left {
                & img {
                    max-height: 320px;
                }
            }

            & .right {
                margin-left: 38px;
            }
        }
    }
}


.boxes-publicidad {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    & .box-publicidad {
        min-width: 300px;
        min-height: 200px;
        margin-bottom: 26px;

        border: 1px solid $post_meta;

        padding: 6px;

        display: flex;
        justify-content: center;
        align-items: center;
    }

}

footer {
    background-color: $bck_footer;
    padding: 39px 0 40px;

    @media (min-width: 992px) {
        padding: 70px 0 80px;
    }

    & .compania {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        margin-bottom: 20px;

        @media (min-width: 992px) {
            flex-direction: row;
            margin-bottom: 0;
        }

        & img {
            width: 150px;

        }

        & .claim {
            margin-top: 37px;
            text-align: center;
            max-width: 400px;
            @media (min-width: 992px) {
                margin-left: 37px;
                margin-top: 0;
                text-align: left;
                max-width: 100%;
            }
        }

    }

    .socials {
        display: flex;
        justify-content: center;
        align-items: center;

        & li {
            margin-left: 16px;
        }
    }
}

.content-ranking{
    display:flex;
    justify-content: flex-start;
    align-items: flex-start;

    &-number{
        background-image: url("../images/ellipse_ranking_back.png");
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 100%;
        margin-right: 20px;
        width: 33px;
        height: 33px;

        display: flex;
        justify-content: center;
        align-items: center;

        font-size:18px;
        font-weight: 600;
        color:#690D1E;
    }
}

#account-setting{
    margin-bottom: 20px;
}

.submit-button{
    &:hover{
        color:#690D1E;
        background:transparent;
        border:1px solid #690D1E;
    }
}





