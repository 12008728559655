/*@import "resources/assets/css/bootstrap.min.css";*/
//@import "resources/assets/css/core.css";
//@import "resources/assets/css/shortcode/shortcodes.css";
//@import "resources/assets/css/style.css";


//@import "resources/assets/css/responsive.css";
//@import "resources/assets/css/custom.css";

@import '../../node_modules/medium-editor/src/sass/medium-editor'; //Insert this one
@import '../../node_modules/medium-editor/src/sass/themes/beagle'; //Insert this one

.menu-wrapper{
    &.bg-theme{
        &.clearfix {
            border: 0 !important;
        }
    }
}

.post-titulo-seccion{
    padding:0 !important;
}


.post-nombre-autor{
    line-height: 0 !important;
}


.post-container-detail {
    max-width: 1035px;
    margin: auto;

    .date{
        font-weight: bold;
    }

    .articulos-similares{
        font-size: 28px;
        color: #690D1E !important;
    }

    & h1 {
        font-size: 28px;
    }

    & p {
        font-size: 21px;
        line-height: 150%;
    }
}

@import 'child';
